<template>
  <Box>
  
    <SavingDialog v-show="showSavingDialog" @close="closeSavingDialog" />
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    <ErrorRow v-if="model" :error="model._error" />

    <LoadingRow 
      :showBorder="true" 
      v-if="isLoading">{{ loadingMessage }}</LoadingRow>
    
    <Row v-else>
      <Column :width="15">
        <Box :key="redraw">
          <Row v-for="(row, rowIndex) in rows" :key="rowIndex" >
            <CellFactory 
              :rowData="row.data"
              :showMissingRequirements="showMissingRequirements"
              v-on:withValue="withValue"
              v-on:withButtonPressed="withButtonPressed"
               />
          </Row>
        </Box>
      </Column>
    </Row>
    
  </Box>
  
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import ErrorRow from '@/components/row/ErrorRow.vue';
//import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import CheckList from '@/domain/model/checklist/CheckList.js';
import CheckListItem from '@/domain/model/checklist/item/CheckListItem.js';
import CheckListItemMap from '@/domain/model/checklist/item/CheckListItemMap.js';
import CheckListItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';

import ChecklistListFilter from '@/domain/model/checklist/ChecklistListFilter.js';
import CheckListMap from '@/domain/model/checklist/CheckListMap.js';

import EditModel from "./EditModel.js";
import EditUtils from "./EditUtils.js";

import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: "checklist-edit-view",
  components: {
    ErrorRow, SavingDialog,
    Column, Box, Row, LoadingRow,
    CellFactory, SessionTimeoutDialog,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: true,
      loadingMessage: null,
      showSavingDialog: false,
      showSessionTimeoutDialog: false,
      showMissingRequirements: false,
      
      redraw: 0,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CheckList: CheckList,
      CheckListMap: CheckListMap,
      CheckListItemMap: CheckListItemMap,
      CheckListItem: CheckListItem,
      CheckListItemOption: CheckListItemOption,
      
      CellFactory: CellFactory,
      
      tableData: [],
      editModel: null,
      timeout: null,
      
      TitleDisplayRow: TitleDisplayRow,
      
      EditUtils: EditUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
    isSaving: function() {
      if (this.editModel) {
        return this.editModel.isSaving();
      }
      return false;
    },
    rows: function() {
      return this.tableData;
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.loadingMessage = this.MC.Status.Done.value();
      this.isLoading = false;
      this.loadChecklist();
    },
    
    loadChecklist: function() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.loadingMessage = this.MC.CheckList.Status.Loading.value();
      
      var filter = new ChecklistListFilter();
        filter
          .withPagination(1, 0)
          .withIds([this.itemId])
          .done();

        filter
          .withCompany(this.domain.session().company().id())
          .done();

        var event = this.domain.events().checklists().list(filter);
        event.send(this.loadChecklistsListener);
    },
    
    loadChecklistsListener: function(response) {
      if (response.error()) {
        this.error = response.error();
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.displayChecklist, 10);
      }
      this.isLoading = false;
    },
    
    displayChecklist: function() {
      var checklist = this.domain.checklists().findById(this.itemId);
      this.editModel = new EditModel(this, checklist);
      this.model().start();
      this.tableData = this.model().tableData();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    withValue: function(kvp) {
      this.model().withValue(kvp);
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);
    },
    
    model: function() {
      return this.editModel;
    },
    
    populateData: function(rowAddedOrRemoved) {
      if (rowAddedOrRemoved) {
        //
      }
      this.tableData = [];
      /*clearTimeout(this.timeout);
      this.timeout = setTimeout(this.reloadData, 10); */
      this.reloadData();
    },
    
    reloadData: function() {
      this.model().populateData();
      this.tableData = this.model().tableData();
      this.redraw += 1;
    },
    
    doSaveAction: function() {
      this.model().doSaveAction();
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
  },
}
</script>